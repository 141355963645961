/* src/components/ListingBook/listingbook.module.css */

.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 2em;
  margin-bottom: 20px;
}

.addLink {
  display: inline-block;
  margin-bottom: 20px;
  color: blue;
  text-decoration: none;
  font-weight: bold;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #ddd;
}

.button {
  padding: 5px 10px;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.button:hover {
  opacity: 1;
}

.updateButton {
  background: linear-gradient(45deg, #ff69b4, #00ffff);
}

.deleteButton {
  background: linear-gradient(45deg, #ff0000, #ff69b4);
}
