/* src/components/UpdateAdmin/updateadmin.module.css */

.container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.card h3 {
    margin-top: 0;
}

.form {
    max-width: 600px;
    margin: 0 auto;
}

.form div {
    margin-bottom: 15px;
}

.form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.button {
    padding: 10px 20px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.button:hover {
    opacity: 1;
}

.updateButton {
    background: linear-gradient(45deg, #ff69b4, #00ffff);
}
