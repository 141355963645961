@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

:root{
    --primaryColor: hsl(94, 55%, 56%);
    --hoverColor: hsl(93, 75%, 76%);
    --paleGreen: hsl(96, 75%, 89%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --grayText: hsl(0, 0%, 75%);
    --bgColor: hsl(113, 11%, 85%);
    --inputColor: hsl(330, 12%, 97%);
    --inputColor: hsl(154, 14%, 90%);

    /*Font and Typography*/
    --biggestFontSize:2.5rem;
    --h1FontSize:1.5rem;
    --h2FontSize:1.25rem;
    --h3FontSize:1rem;
    --normalFontSize:0.938rem;
    --smallFontSize:0.813rem;
    --smallestFontSize:0.75rem;
}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 20px;
}

html{
    font-size: 90%;
}

a{
    text-decoration: none;
}

li{
    list-style: none;
}

.icon{
    font-size: var(--h2FontSize);
    cursor: pointer;
}

img,video{
    height: auto;
    width: 100%;
}

.message{
    display: none;
}

.showMessage{
    display: block;
    color: var(--whiteColor);
    background: rgb(255, 0, 0);
    border-radius: 3px;
    text-align: center;
}

.btn{
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: .8rem 1rem;
    color: var(--grayText);
    box-shadow: 0 2px 4px var(--inputColor);
}

.btn .icon{
    margin-left: 0.5rem;
}

.btn:hover{
    color: var(--primaryColor);
    background: var(--paleGreen);
}

.body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

//dashboard stylings
.container{
    margin-top: 1.5rem;
        position: relative;
        width: 95vw;
        height: 95vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bgColor);
        overflow: hidden;
        border-radius: 1rem;
        box-shadow: 2px 2px 8px var(--grayText);
}

::-webkit-scrollbar{
    display: none;
}


//page stylings
.sideBar{
    background: var(--whiteColor);
    box-shadow: 1px 0 4px var(--grayText);
    width: 18%;
    height: 100%;
    gap: 3rem;
    overflow: auto;

    .logoDiv{
        padding: 1.5rem 1.5rem 0;
        cursor: pointer;

        img{
            max-width: 20px;
            margin-right: 0.5rem;
            background: var(--inputColor);
            border-radius: 2px;
            padding: .2rem;
        }

        h2{
            font-size: var(--h2FontSize);
            color: var(--primaryColor);

        }
    }
    .menuDiv,.settingsDiv{
        .divTitle{
            font-size: var(--h3FontSize);
            color: var(--blackColor);
            padding: 0 1.5rem 1.5rem;
        }
        .menuLists{
            .listItem{
                padding: 0.2rem 1.5rem;
                width: 100%;
                position: relative;

                &::before{
                    position: absolute;
                    content: '';
                    height: 0;
                    left: 0;
                    width: 5px;
                    bottom: 0;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background: var(--primaryColor);
                }

                .menuLink{
                    color: var(--grayText);
                    font-weight: 500;
                    transition: 0;
                    .icon{
                        margin-right: 0.5rem;
                    }
                }

                &:hover .menuLink{
                    color: var(--primaryColor);
                    transition: 0;
                }

                &:hover::before{
                    height: 100%;
                    transition: .3s ease;
                }
            }
        }
    }

    .sideBarCard{
        width: 100%;
        padding: 2rem;
        text-align: center;
        position: relative;

        .icon{
            position: absolute;
            background: var(--hoverColor);
            border: 10px solid var(--whiteColor);
            font-size: 3rem;
            border-radius: 50%;
            top: -8px;
            right: 50%;
            transform: translate(50%);
            z-index: 100;
        }

        .cardContent{
            position: relative;
            padding: 1rem;
            background: var(--hoverColor);
            border-radius: 10px;
            overflow: hidden;

            h3{
                font-size: var(--h3FontSize);
                margin-top: 1rem;
                padding: 1rem 0;
                font-weight: 800;
                color: var(--blackColor);
            }

            p{
                font-size: var(--normalFontSize);
                padding-bottom: 1rem;
                font-weight: 500;
            }

            .btn{
                position: relative;
                z-index: 1000;
            }

            .circle1, .circle2{
                position: absolute;
                background: var(--paleGreen);
                border-radius: 50%;
                opacity: .7;
            }

            .circle1{
                height: 100px;
                width: 100px;
                top: -50px;
                left: -50px;
            }

            .circle2{
                height: 150px;
                width: 150px;
                bottom: -80px;
                right: -70px;
            }
        }
    }
}

.mainContent{
    width: 82%;
    height: 100%;
    padding: 2rem;
    overflow: auto;

    .bottom{
        margin-top: 2rem;
        gap: 2rem;
        align-items: flex-start;
    }
}

.topSection{
    .headerSection{
        justify-content: space-between;

        .title{
            h1{
                font-size: var(--h1FontSize);
                color: var(--blackColor);
                font-weight: 700;
            }
    
            p{
                font-size: var(--normalFontSize);
                color: var(--primaryColor);
                font-weight: 500;
            }
        }

        .searchBar{
            padding: 1rem 2rem;
            background: var(--whiteColor);
            border-radius: 5px;
            gap: 1rem;
            box-shadow: 0px 2px 4px var(--inputColor);

            input{
                border: none;
                outline: none;
                background: none;
            }

            .icon{
                color: var(--grayText);

                &:hover{
                    color: var(--primaryColor);
                }
            }
        }

        .adminDiv{
            gap: 1rem;

            .icon{
                font-size: var(--biggestFontSize);
                background: var(--whiteColor);
                color: var(--whiteColor);
                border-radius: 5px;
                padding: 5px;
                box-shadow: 0 2px 4px var(--inputColor);
                color: var(--primaryColor);
            }

            .adminImage{
                border: 3px solid var(--whiteColor);
                border-radius: 5px;
                width: 2.5rem;
                overflow: hidden;
                box-shadow: 0 2px 4px var(--inputColor);

                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
        }
                    
    }


    .cardSection{
        margin-top: 3rem;
        gap: 1rem;

        .rightCard{
            position: relative;
            flex-basis: 70%;
            height: 200px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin: auto;
            padding: 2rem;
            border-radius: 1rem;
            overflow: hidden;

            .videoDiv{
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                video{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        
    }
}
