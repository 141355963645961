@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

:root{
    --primaryColor: hsl(94, 55%, 56%);
    --hoverColor: hsl(93, 75%, 76%);
    --paleGreen: hsl(96, 75%, 89%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --grayText: hsl(0, 0%, 75%);
    --bgColor: hsl(113, 11%, 85%);
    --inputColor: hsl(330, 12%, 97%);
    --inputColor: hsl(154, 14%, 90%);

    /*Font and Typography*/
    --biggestFontSize:2.5rem;
    --h1FontSize:1.5rem;
    --h2FontSize:1.25rem;
    --h3FontSize:1rem;
    --normalFontSize:0.938rem;
    --smallFontSize:0.813rem;
    --smallestFontSize:0.75rem;
}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
    gap: 1.5rem;
}

html{
    font-size: 90%;
}

a{
    text-decoration: none;
}

li{
    list-style: none;
}

.icon{
    font-size: var(--h2FontSize);
    cursor: pointer;
}

img,video{
    height: auto;
    width: 100%;
}

.message{
    display: none;
}

.showMessage{
    display: block;
    color: var(--whiteColor);
    background: rgb(255, 0, 0);
    border-radius: 3px;
    text-align: center;
}

.btn{
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: .8rem 1rem;
    color: var(--grayText);
    box-shadow: 0 2px 4px var(--inputColor);
}

.btn .icon{
    margin-left: 0.5rem;
}

.btn:hover{
    color: var(--primaryColor);
    background: var(--paleGreen);
}

.body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

//dashboard stylings
.dashboardPage{
    .container{
        position: relative;
        width: 95vw;
        height: 95vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bgColor);
        overflow: hidden;
        border-radius: 1rem;
        box-shadow: 2px 2px 8px var(--grayText);
    }
}

//page stylings
.loginPage, .registerPage{
    height: 100vh;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    box-sizing: 0 7px 50px rgb(214,223,213);

    .container{
        height: 75vh;
        width: 60%;
        margin: auto;
        justify-content: space-between;
        border-radius: 10px;
        background: hsl(0, 0%, 91%);

        .videoDiv{
            position: relative;
            display: flex;
            flex-basis: 50%;
            width: 70%;
            height: 100%;
            padding: 0 1.5rem;
            text-align: center;
            justify-content: center;
            flex-direction: column;
            margin: auto;
            border-radius: 10px;
            overflow: hidden;

            video, .footerDiv{
                position: absolute;
            }
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .textDiv{
                position: relative;

                .title{
                    color: var(--whiteColor);
                    font-size: 35px;
                    font-weight: 800;
                }

                p{
                    color: var(--primaryColor);
                    padding: 1rem;
                    font-weight: 400;
                }
            }

            .footerDiv{
                bottom: 10px;
                padding: 0 1rem;
                height: 60px;
                background: rgba(255, 255, 255, 0.248);
                left: 0;
                right: 0;
                justify-content: space-between;
                backdrop-filter: blur(1px);
                border-radius: 10px;

                .text{
                    color: var(--whiteColor);
                }

                .btn{
                    background: var(--whiteColor);
                    color: var(--primaryColor);
                    padding: 0.8rem 1.5rem;
                    border-radius: 10px;
                    font-weight: 400;
                    transition: 0.3s ease;

                    &:hover{
                        background: var(--hoverColor);
                        color: var(--blackColor);
                    }
                }
            }
        }
        .formDiv{
            flex-basis: 50%;
            margin: auto;
            flex-direction: column;
            gap: 1.5rem;
            transform: translateY(-1rem);

            .headerDiv{
                text-align: center;
                padding: 1.5rem 0;

                img{
                    width: 60px;
                    padding-bottom: .5rem;
                }

                h3{
                    font-size: 25px;
                    color: var(--blackColor);
                    font-weight: 600;
                }
            }

            .registerDiv{
                text-align: center;
                padding: 0 0;

                img{
                    width: 60px;
                    padding-bottom: .5rem;
                }

                h3{
                    font-size: 25px;
                    color: var(--blackColor);
                    font-weight: 600;
                }
            }

            .form{
                gap: 1rem;

                .inputDiv{
                    .icon{
                        color: var(--grayText);
                    }

                    input{
                        background: none;
                        outline: none;
                        border: none;
                        width: 200px;
                    }

                    label{
                        color: var(--blackColor);
                        font-weight: 800;
                        font-size: 14px;
                        padding: 0.5rem 0;
                        display: block;
                    }

                    .input{
                        gap: 0.5rem;
                        padding: 1rem;
                        background: var(--inputColor);
                        border-radius: 10px;
                    }

                    .inputRegister{
                        gap: 0.5rem;
                        padding: .5rem 1rem;
                        background: var(--inputColor);
                        border-radius: 10px;
                    }
                }

                .btn{
                    padding: 0.8rem;
                    background: var(--primaryColor);
                    border-radius: 10px;
                    color: var(--whiteColor);
                    align-items: center;
                    margin: auto;
                    justify-content: center;
                    font-size: 16px;
                    width: 100%;
                    gap: 1.5rem;
                    transition: .3s ease;

                    .icon{
                        font-size: 25px;
                        transition: .3s ease;
                    }

                    &:hover{
                        background: var(--hoverColor);
                        color: var(--blackColor);

                        .icon{
                            color: var(--blackColor);
                            transform: translateX(5px);
                        }
                    }
                }
                .forgotPassword{
                    font-size: 13px;
                    color: var(--blackColor);
                    opacity: .7;
                    text-align: center;

                    a{
                        text-decoration: underline;
                        cursor: pointer;
                        opacity: 1;
                    }
                }
            }
        }
        .dorm{
            gap: 0.001rem;
            margin-top: -10px;

            .inputDiv{
                .icon{
                    color: var(--grayText);
                }

                input{
                    background: none;
                    outline: none;
                    border: none;
                    width: 200px;
                }

                label{
                    color: var(--blackColor);
                    font-weight: 800;
                    font-size: 14px;
                    padding: 0.5rem 0;
                    display: block;
                }

                .input{
                    gap: 0.5rem;
                    padding: 1rem;
                    background: var(--inputColor);
                    border-radius: 10px;
                }

                .inputRegister{
                    gap: 0.5rem;
                    padding: .5rem 1rem;
                    background: var(--inputColor);
                    border-radius: 10px;
                }
            }

            .btn{
                padding: 0.8rem;
                background: var(--primaryColor);
                border-radius: 10px;
                color: var(--whiteColor);
                align-items: center;
                margin: auto;
                justify-content: center;
                font-size: 16px;
                width: 100%;
                gap: 1.5rem;
                transition: .3s ease;

                .icon{
                    font-size: 25px;
                    transition: .3s ease;
                }

                &:hover{
                    background: var(--hoverColor);
                    color: var(--blackColor);

                    .icon{
                        color: var(--blackColor);
                        transform: translateX(5px);
                    }
                }
            }
            .forgotPassword{
                font-size: 13px;
                color: var(--blackColor);
                opacity: .7;
                text-align: center;

                a{
                    text-decoration: underline;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    
    }
}